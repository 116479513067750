<template>
  <p 
    class="mb-4 rounded-md px-4 py-2"
    :class="[
      sizeClasses[size],
      typeClasses[type]
    ]"
  >
    {{ text }}
  </p>
</template>

<script setup lang="ts">
type InfoType = 'primary' | 'secondary' | 'error' | 'warning' | 'success' | 'done' | 'info'
type InfoSize = 'small' | 'medium' | 'large'

interface Props {
  text: string
  type?: InfoType
  size?: InfoSize
}

const props = withDefaults(defineProps<Props>(), {
  type: 'primary',
  size: 'medium',
})

const typeClasses = {
  primary: 'bg-color-button-primary text-color-label-1',
  secondary: 'bg-color-button-secondary text-white',
  error: 'bg-color-error text-white',
  warning: 'bg-color-warning text-color-label-1',
  success: 'bg-color-success text-white',
  done: 'bg-color-done text-white',
  info: 'bg-color-info text-color-label-3',
} as const

const sizeClasses = {
  small: 'text-label-3',
  medium: 'text-label-2',
  large: 'text-label-1',
} as const
</script>
