<template>
  <div
    class="flex min-h-[64px] min-w-[64px] items-center justify-center rounded-xl border hover:bg-1-hover"
    :class="[{ 'w-full': !hasPhotos, 'mr-1': hasPhotos }]"
    @click="handleCapture"
  >
    <label class="flex items-center">
      <CameraIcon class="w-7 text-primary" :class="[{ 'mr-2': !hasPhotos }]" />
      <p v-if="!hasPhotos" class="text-label-bold-2 text-color-link-static">
        {{ $t("post.item.photos.add_photo") }}
      </p>
    </label>
  </div>
</template>

<script setup lang="ts">
import { CameraIcon } from "@heroicons/vue/24/outline"
import { captureImage, urlToFile } from "@/utils/fileUtils"

const props = defineProps<{
  hasPhotos: boolean
}>()

const emit = defineEmits<{
  (e: 'captureComplete', file: File, url: string): void
}>()

async function handleCapture() {
  const image = await captureImage()
  if (!image?.webPath) return
  
  const file = await urlToFile(image.webPath)
  if (file) {
    emit('captureComplete', file, image.webPath)
  }
}
</script>
