<template>
  <PageLayout>
    <template #header>
      <TopNav 
        :title="$t('company-settings.certificates.title')" 
        @back="$router.push({ name: 'settings' })" 
      />
    </template>

    <template #default>
      <div class="mx-auto max-w-7xl space-y-8">
        <!-- Description -->
        <Info
          :text="$t('company-settings.certificates.description')"
          type="info"
          size="medium"
        />

        <!-- Certificate Gallery -->
        <div class="mt-4 rounded-lg shadow-sm">
          <div v-if="certificateFileIds.length">
            <ImageGallery
              :file-ids="certificateFileIds"
              :wrap-photos="true"
              :allow-delete="true"
              @delete-image="handleCertificateDelete"
              size="large"
              class="mt-4"
            />
          </div>
          <Info 
            v-else
            :text="$t('company-settings.certificates.no_certificates')"
            type="info"
            size="medium"
          />

          <!-- Certificate Upload -->
          <div class="mt-6">
            <ImageUpload
              :has-photos="Boolean(certificateFileIds.length)"
              @capture-complete="handleCertificateUpload"
            />
          </div>
        </div>
      </div>
    </template>
  </PageLayout>
</template>

<script setup lang="ts">
import { useGlobalStore } from '@/stores/globalStore'
import { uploadFileWithRelation } from '@/utils/fileUtils'

// Principles Applied:
// 1. Single Responsibility: Component only handles certificate management
// 2. Meaningful Names: Clear variable and function names
// 3. High Cohesion: Related functionality grouped together

const dataStore = useDataStore()
const deleteStore = useDeleteStore()
const tenant = computed(() => dataStore.tenantsById[dataStore.currentTenant || ''])

// Computed property to extract file IDs from certificates
const certificateFileIds = computed(() => 
  tenant.value?.certifications?.map(cert => cert.directus_files_id) || []
)

// Handle certificate upload
async function handleCertificateUpload(file: File) {
  try {
    // Upload file and create relationship
    await uploadFileWithRelation(
      file,
      tenant.value?.id || '',
      'tenants_files',
      'tenants_id',
      tenant.value?.id || ''
    )
  } catch (error) {
    console.error('Failed to upload certificate:', error)
  }
}

// Handle certificate deletion
async function handleCertificateDelete(id: string) {
  if (!tenant.value) return

  const certifications = tenant.value.certifications
  const certificationIdToDelete = certifications.find(c => c.directus_files_id === id)?.id ?? ""

  if (!certificationIdToDelete) {
    console.error("No certification id to delete")
    return
  }

  deleteStore.setItemToDelete(
    certificationIdToDelete,
    "tenants_files",
  )

  // Watch for deletion confirmation
  watch(
    () => deleteStore.itemDeleted,
    async (deleted) => {
      if (deleted && deleteStore.itemToDelete?.id === certificationIdToDelete) {
        // Update local state
        

        // First, update the tenants_id to null to remove it from aliases
        await directus.updateGeneric({
          collection: 'tenants_files',
          id: certificationIdToDelete,
          data: {
            tenants_id: null
          }
        })

        // Reset delete store state
        deleteStore.clearItemToDelete()
        deleteStore.itemDeleted = false
      }
    },
    { immediate: true }
  )
}
</script>
