import type { User } from "@/types"
import type { IEmployee, ITenant } from "@/types/DateTypes"
import api from "@/api"
import { t } from "@/localization/i18n"
import { toast } from "@/plugins/toasterPlugin"
import router from "@/router"
import initials from "@/types/initialValues"
import { createFullstorySession } from "@/utils/fullstory"
import { initializePendo } from "@/utils/pendo"
import { primaryKey } from "@/utils/primaryKey"
import { FirebaseAuthentication } from "@capacitor-firebase/authentication"
import { defineStore } from "pinia"
import { computed, ref } from "vue"

export const useGlobalStore = defineStore("global", () => {
  // State
  const currentUserId = ref<string>("")
  const user = ref<User>(initials.defaultUser)
  const tenant = ref<ITenant>(initials.defaultTenantValue)
  const employee = ref<IEmployee | null>(null)
  const isDeveloper = ref(false)
  const loading = ref(false)
  const showError = ref(false)
  const isLoggedIn = ref(false)
  const token = ref<string | null>(null)
  const firebaseToken = ref<string | null>(null)
  const lastVisitedPage = ref("/")
  const loginStatus = ref<
    "email" | "phone" | "waiting-verification" | "completed" | "error"
  >("phone")
  const showMessageVerificationCodeSentMessage = ref(false)
  const mobileNumber = ref("")
  const passwordResetStatus = ref<"not-started" | "started" | "completed">("not-started")
  const hydrated = ref(false)

  // Actions
  async function hydrate(redirectToLoginWhenNotLoggedIn = true) {
    await checkAuth(redirectToLoginWhenNotLoggedIn)
    if (isLoggedIn.value) {
      user.value = await api.users.getMe()
      await authenticateWithFirebase()
      currentUserId.value = user.value.id

      await setTenant()
      setEmployee(user.value.active_tenant)
      isDeveloper.value = user.value.developer || false
      checkUserHasAnCompatableRole()
      isLoggedIn.value = true
      hydrated.value = true
    }
  }

  async function loginEmail(values: { email: string, password: string }) {
    loading.value = true
    try {
      await api.client.login(values.email, values.password)

      loginStatus.value = "completed"
      await hydrate()
      showError.value = false
      loading.value = false
    }
    catch (error) {
      console.log(error)
      showError.value = true
    }
    finally {
      loading.value = false
    }
  }

  async function loginPhone(phoneNumber: string) {
    showMessageVerificationCodeSentMessage.value = true
    mobileNumber.value = phoneNumber
    loading.value = true

    try {
      const result = await api.users.sendUserSMSCode(phoneNumber)
      if (result) {
        loginStatus.value = "waiting-verification"
        showError.value = false
      }
      else {
        showError.value = true
        showMessageVerificationCodeSentMessage.value = false
      }
    }
    catch (error) {
      console.error("Error sending SMS code:", error)
      showError.value = true
      showMessageVerificationCodeSentMessage.value = false
    }
    finally {
      loading.value = false
    }
  }

  async function verifyCode(code: number) {
    loginStatus.value = "waiting-verification"
    loading.value = true
    const result = await api.users.verifyUserSMSCode({
      code: code.toString(),
      mobile: mobileNumber.value,
      createUser: false,
    })
    if (result.status && result.user?.email) {
      const user = result.user

      await loginEmail({
        email: user.email,
        password: code.toString(),
      })

      loading.value = false
    }
    else {
      showError.value = true
      loginStatus.value = "phone"
      loading.value = false
      showMessageVerificationCodeSentMessage.value = false
      console.error("user email missing")
    }
    showMessageVerificationCodeSentMessage.value = false
  }

  async function resetPassword(newPassword: string) {
    loading.value = true
    try {
      await api.users.updateUserPassword(newPassword)
      passwordResetStatus.value = "completed"
      toast.success(t("auth.password_updated"))
    }
    catch (error) {
      console.log(error)
      toast.error(t("auth.password_update_failed"))
      showError.value = true
    }
    finally {
      loading.value = false
    }
  }

  async function setTenant(tenantId?: string) {
    if (!user.value.tenants) {
      await router.push({ name: "onboarding" })
    }

    if (tenantId) {
      const requestedTenant = user.value.tenants.find(
        ({ tenant, status }) =>
          primaryKey(tenant, "id") === tenantId && status === "active",
      )?.tenant

      if (requestedTenant) {
        tenant.value = requestedTenant

        await api.users.updateActiveTenant(tenantId)
      }
      else {
        tenant.value = initials.defaultTenantValue
      }
    }
    else if (user.value.active_tenant) {
      const activeTenanant = user.value.tenants.find(
        ({ tenant, status }) =>
          primaryKey(tenant, "id") === user.value.active_tenant && status === "active",
      )

      if (activeTenanant) {
        tenant.value = activeTenanant.tenant
      }
      else {
        tenant.value = user.value.tenants[0].tenant
        await api.users.updateActiveTenant(primaryKey(tenant.value, "id"))
      }
    }

    tenant.value.$countrySetting = getCountriesConfig().map.get(tenant.value.country_code)

    localStorage.setItem("tenant", tenant.value?.id ?? "")
    createFullstorySession(user.value, tenant.value)
    initializePendo(user.value, tenant.value)
  }

  function setEmployee(activeTenantId: string) {
    employee.value
      = user.value.tenants.find(({ tenant }) => tenant.id === activeTenantId) || null
    if (!employee.value) {
      console.error("Employee not found")
    }
  }

  async function checkAuth(redirectToLoginWhenNotLoggedIn = true) {
    try {
      const result = (await api.client.refresh()).access_token

      if (!result) {
        handleLogout(redirectToLoginWhenNotLoggedIn)
      }
      else {
        isLoggedIn.value = true
        // Optionally redirect to last visited page
        // await router.push({ path: lastVisitedPage.value })
      }
    }
    catch (error) {
      console.error("Error during auth refresh:", error)
      resetState()
      if (redirectToLoginWhenNotLoggedIn) {
        await router.push({ name: "auth.loginOptions" })
      }
    }
  }

  async function handleLogout(redirectToLoginWhenNotLoggedIn: boolean) {
    console.log("User is not logged in anymore, redirecting to login page")
    resetState()
    if (redirectToLoginWhenNotLoggedIn) {
      await router.push({ name: "auth.loginOptions" })
    }
  }

  async function signOut() {
    try {
      localStorage.setItem("logging-out", "true")
      await api.client.logout()
    }
    catch (error) {
      console.error("Error logging out", error)
    }

    localStorage.removeItem("tenant")
    resetState()
    await router.push({ name: "auth.loginOptions" })
  }

  function checkUserHasAnCompatableRole() {
    if (
      user.value.role.id !== "a54e1e8d-308c-4c24-86a0-6855c33381e5"
      && user.value.role.id !== "ae36873d-9100-4a81-bd98-4a87855d8b91"
    ) {
      alert(
        "The role you have is not compatable with what the application supports. Please contact support.",
      )
      signOut()
    }
  }

  async function deleteTenant() {
    loading.value = true
    await api.callN8nWorkflow({
      tenant_id: tenant.value.id,
      path: "/delete-tenant",
    })
    loading.value = false
    await signOut()
  }

  function resetState() {
    user.value = initials.defaultUser
    tenant.value = initials.defaultTenantValue
    loading.value = false
    showMessageVerificationCodeSentMessage.value = false
    showError.value = false
    isLoggedIn.value = false
    hydrated.value = false
  }

  type AccessTypes = keyof NonNullable<IEmployee["access"]>

  function requireTheFollowingAccess(accessType: AccessTypes): ComputedRef<boolean> {
    const dataStore = useDataStore()

    return computed(() => {
      const currentEmployeeRef = dataStore.currentUser?.$employee
      const currentEmployee = toValue(currentEmployeeRef)

      if (currentEmployee?.role === "admin") {
        return true
      }

      if (currentEmployee?.access) {
        return currentEmployee.access[accessType] || false
      }

      return false
    })
  }

  async function authenticateWithFirebase() {
    if (!isLoggedIn.value) {
      console.error("Cannot authenticate with Firebase: User is not logged in")
      return false
    }

    try {
      const response = (await api.client.request(() => ({
        path: "/firebase-auth",
        method: "POST",
      }))) as { success: boolean, firebaseToken: string }

      if (!response.success) {
        showError.value = true
        return false
      }

      await FirebaseAuthentication.signInWithCustomToken({
        token: response.firebaseToken,
      })
      firebaseToken.value = response.firebaseToken
      return true
    }
    catch (error) {
      console.error("Firebase authentication failed:", error)
      showError.value = true
      return false
    }
  }

  return {
    // State
    currentUserId,
    user,
    tenant,
    employee,
    isDeveloper,
    loading,
    showError,
    isLoggedIn,
    token,
    firebaseToken,
    lastVisitedPage,
    loginStatus,
    showMessageVerificationCodeSentMessage,
    mobileNumber,
    passwordResetStatus,
    hydrated,

    // Actions
    hydrate,
    loginEmail,
    loginPhone,
    verifyCode,
    resetPassword,
    setTenant,
    setEmployee,
    checkAuth,
    signOut,
    checkUserHasAnCompatableRole,
    deleteTenant,
    resetState,
    requireTheFollowingAccess,
  }
})
