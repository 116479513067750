<template>
  <div class="flex my-4">
    <ImageUpload
      :has-photos="hasPhotos"
      @capture-complete="handleImageCapture"
    />
    <ImageGallery
      :file-ids="fileIds"
      :wrap-photos="false"
      :allow-delete="true"
      :edit-mode="true"
      class="overflow-x-auto"
      @delete-image="handleImageDelete"
    />
  </div>
</template>

<script setup lang="ts">
import { usePostStore } from "@/stores/postStore"
import { useImageStore } from "@/stores/imageStore"
import { useGlobalStore } from "@/stores/globalStore"
import { useDeleteStore } from "@/stores/useDeleteStore"
import { watch, computed } from 'vue'

const postStore = usePostStore()
const imageStore = useImageStore()
const globalStore = useGlobalStore()
const deleteStore = useDeleteStore()

const hasPhotos = computed(() => postStore.post?.pictures.length !== 0)
const fileIds = computed(() => postStore.post?.pictures.map(item => item.directus_files_id.id) ?? [])

async function handleImageCapture(file: File) {
  await imageStore.handleImage(file)
}

async function handleImageDelete(id: string) {
  if (!postStore.post) return

  const images = postStore.post.pictures
  const imageIdToDelete = images.find(p => p.directus_files_id.id === id)?.id ?? ""

  if (!imageIdToDelete) {
    console.error("No image id to delete")
    return
  }

  deleteStore.setItemToDelete(
    imageIdToDelete,
    "posts_files",
  )

  // Watch for deletion confirmation
  watch(
    () => deleteStore.itemDeleted,
    async (deleted) => {
      if (deleted && deleteStore.itemToDelete?.id === imageIdToDelete) {
        // Update local state
        postStore.post.pictures = postStore.post.pictures.filter(
          picture => picture.id !== imageIdToDelete
        )

        // Reset delete store state
        deleteStore.clearItemToDelete()
        deleteStore.itemDeleted = false
      }
    },
    { immediate: true }
  )
}
</script>
