<template>
  <PageLayout v-if="!customerStore.loading">
    <template #header>
      <div
        v-if="
          projectStore.status !== 'new-pick-customer'
            && projectStore.status !== 'edit-pick-customer'
        "
        class="flex items-center"
      >
        <h2 v-show="!showCustomerEdit" class="grow text-heading-2">
          {{ $t("menu-mobile.customers") }}
        </h2>
        <div>
          <Button v-if="!mobile" size="medium" icon="plus" @click="goToCustomer">
            {{
              $t("new_customer")
            }}
          </Button>
        </div>
      </div>
      <div v-else class="flex items-center justify-between">
        <RouterLink :to="{ name: 'quotes' }">
          <IconExit />
        </RouterLink>
        <p class="text-label-bold-1">
          {{ $t("select_customer") }}
        </p>
        <AtomSpinner v-if="projectStore.loading" :size="64" />
        <Button size="medium" icon="plus" @click="goToCustomer">
          {{ $t("new_customer") }}
        </Button>
      </div>
      <SearchInputV2
        v-show="customerStore.customers.length > 7"
        v-model="searchTerm"
        class="mt-4"
        @reset="() => (searchTerm = '')"
      />
    </template>
    <template #default>
      <div v-show="!showCustomerEdit" class="mb-32 mt-6">
        <TransitionGroup name="fade">
          <div
            v-for="c in searchTerm.trim()
              ? customerStore.filtered
              : customerStore.customers"
            :key="c.id"
          >
            <CustomersSummary
              :customer="c"
              :mode="mode"
              data-testid="customer-item"
              @edit="goToCustomer(c)"
              @select="addClientToProject(c)"
            />
          </div>
        </TransitionGroup>
      </div>
      <CustomersPlaceholder v-if="customerStore.customers.length === 0" />
    </template>
    <template #footer-mobile>
      <div
        v-if="projectStore.status === 'idle' && mobile"
        class="fixed inset-x-0 bottom-20 w-full px-4 pb-safe"
      >
        <Button
          v-if="!showCustomerEdit"
          icon="plus"
          class="w-full"
          size="large"
          @click="goToCustomer"
        >
          {{ $t("customers.new_customer") }}
        </Button>
      </div>
    </template>
  </PageLayout>
  <Loader v-else />
</template>

<script setup lang="ts">
import type { ICustomer } from "@/types/DateTypes"

const projectStore = useProjectStore()

const customerStore = useCustomerStore()
const router = useRouter()
const showCustomerEdit = ref(false)
const mode = computed(() => {
  if (
    projectStore.status === "new-pick-customer"
    || projectStore.status === "edit-pick-customer"
  ) {
    return "select"
  }
  else {
    return "edit"
  }
})

const searchTerm = ref("")
watchDebounced(
  searchTerm,
  () => {
    const term = searchTerm.value.trim()
    if (term) {
      customerStore.search(term)
    }
  },
  {
    debounce: 200,
  },
)

async function addClientToProject(customer: ICustomer) {
  if (projectStore.status === "new-pick-customer") {
    projectStore.project.customer = customer.id
    projectStore.project.name = customer.street_address ?? ""
    projectStore.status = "edit"
    await projectStore.saveProject()
  }
  else if (projectStore.status === "edit-pick-customer") {
    projectStore.project.customer = customer
    await projectStore.updateProject()
  }
  await router.push({
    name: "quote",
    params: { quoteId: projectStore.project.id },
  })
}

async function goToCustomer(customer?: ICustomer) {
  if (customer?.id) {
    await router.push({ name: "customer", params: { id: customer.id } })
  }
  else {  
    await router.push({ name: "customer" })
  }
}

const showBottomNav = inject("showBottomNav") as Ref<boolean>

onMounted(() => {
  if (projectStore.status === "idle") {
    showBottomNav.value = true
  }
  else {
    showBottomNav.value = false
  }
})
onUnmounted(() => {
  showBottomNav.value = false
})

const mobile = inject("isPhone") as Ref<boolean>
</script>
