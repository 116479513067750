<template>
  <div v-if="editing" :class="[$attrs.class, { 'min-h-[102px]': !withoutMinHeight }]">
    <label
      v-if="name !== ''"
      :class="{ 'text-red-700': error }"
      class="block mb-1 text-label-2 text-color-label-3"
    >
      {{ name }}
    </label>
    <div class="relative">
      <textarea
        :id="id"
        ref="input"
        v-model="fieldValue"
        :class="classNames"
        :placeholder="placeholder"
        :disabled="disabled"
        :style="{ height: currentHeight }"
        class="block w-full bg-white rounded-lg border-divider text-label-2 text-color-label-3"
        :rows="rows"
        @blur="handleBlur"
        @focus="handleFocus"
        @click="handleClick"
      />
      <div v-if="shouldAutoCollapse" class="absolute z-10 bottom-2 right-2">
        <BaseButton
          variant="secondary"
          class="p-1 rounded-lg"
          @click.prevent="isExpanded = !isExpanded"
        >
          <ChevronDownIcon v-if="!isExpanded && !isFocused" class="w-5 h-5" />
          <ChevronUpIcon v-else class="w-5 h-5 !text-color-link-static" />
        </BaseButton>
      </div>
      <div class="absolute bottom-8 right-8">
        <SaveState :is-loading="loading" type="symbol" />
      </div>
    </div>
  </div>
  <div v-else :class="$attrs.class" class="text-left text-gray-900">
    <slot>
      <p class="mb-1" :class="{ 'min-h-[102px]': !withoutMinHeight }">
        <span
          v-if="name !== '' && showNameOnView"
          :class="$attrs.class"
          class="text-gray-400"
        >
          {{ name }}: <br>
        </span>
        <span :class="textClassNames">{{ displayValue ?? modelValue }}</span>
      </p>
    </slot>
  </div>
</template>

<script setup lang="ts">
import type { DirectusContext } from "@/types"
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/vue/24/outline"

const props = withDefaults(
  defineProps<{
    id?: string
    name?: string
    modelValue?: string | null
    placeholder?: string
    displayValue?: string
    disabled?: boolean
    showNameOnView?: boolean
    error?: boolean
    textSize?: "normal" | "lg"
    bold?: boolean
    withoutMinHeight?: boolean
    rows?: number
    rules?: string
    directusContext?: DirectusContext
    autoCollapse?: boolean
    forceAutoCollapse?: boolean
  }>(),
  {
    id: undefined,
    name: "",
    placeholder: "",
    modelValue: "",
    displayValue: undefined,
    disabled: false,
    showNameOnView: true,
    error: false,
    textSize: "normal",
    bold: false,
    withoutMinHeight: false,
    rows: 3,
    rules: undefined,
    directusContext: undefined,
    autoCollapse: undefined,
    forceAutoCollapse: undefined,
  },
)

const emit = defineEmits(["update:modelValue", "blur", "saving"])
const editing = inject<boolean>("editing", true)
const input = ref<HTMLTextAreaElement | null>(null)
const mobile = inject("isPhone") as Ref<boolean>
const isExpanded = inject("isExpanded", ref(false))
const isFocused = ref(false)

defineExpose({ input })

const shouldAutoCollapse = computed(() => {
  return (
    props.forceAutoCollapse
    || (props.autoCollapse !== undefined ? props.autoCollapse : mobile.value)
  )
})

const { fieldValue, validateOnBlur, loading } = useTextAreaState(props, emit)

const { currentHeight, grow } = useTextAreaHeight({
  rows: props.rows,
  mobile,
  isExpanded,
  isFocused,
  shouldAutoCollapse,
  input,
})

const classNames = computed(() => ({
  "border-red-500 focus:ring-red-500 focus:border-red-500": props.error,
  "border-gray focus:ring-indigo-500 focus:border-indigo-500": !props.error,
  "text-lg leading-6": props.textSize === "lg",
  "text-sm": props.textSize === "normal",
  "font-extrabold": props.bold,
}))

const textClassNames = computed(() => ({
  "text-lg leading-6": props.textSize === "lg",
  "text-sm": props.textSize === "normal",
  "font-extrabold": props.bold,
}))

function handleBlur() {
  isFocused.value = false
  validateOnBlur()
  emit("blur")
  if (shouldAutoCollapse.value) {
    isExpanded.value = false
    input.value!.style.height = `${props.rows * 24}px`
  }
}

function handleFocus() {
  if (!mobile.value) {
    isFocused.value = true
    grow()
    return
  }

  if (isExpanded.value) {
    isFocused.value = true
    grow()
  }
  else {
    isExpanded.value = true
    input.value?.blur()
  }
}

function handleClick(event: MouseEvent) {
  if (mobile.value && !isExpanded.value) {
    event.preventDefault()
    isExpanded.value = true
    input.value?.blur()
  }
}

watch(
  () => fieldValue.value,
  () => {
    if (!shouldAutoCollapse.value || isExpanded.value || isFocused.value) {
      grow()
    }
  },
)

onMounted(() => {
  if (shouldAutoCollapse.value) {
    input.value!.style.height = `${props.rows * 24}px`
  }
  else {
    grow()
  }
})
</script>
