import api from "@/api"

export async function fileUrl(
  id: string,
  token?: string | null,
  thumbnail?: boolean,
): Promise<string> {
  let thumbnailKey = ""
  if (thumbnail) {
    thumbnailKey = "&key=thumbnail"
  }

  if (!token) {
    console.error("No token found, cannot fetch image")
    throw new Error("No token found, cannot fetch image")
  }

  return `${api.client.url}assets/${id}?access_token=${token}${thumbnailKey}`
}
