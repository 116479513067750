<template>
  <HeaderOnboarding>
    <div>
      <div class="flex flex-col items-center">
        <h2 class="text-heading-2">
          {{ $t("onboarding.company_1") }}
        </h2>
        <p class="label1 mb-4 mt-2 text-center">
          {{ $t("onboarding.company_2") }}
        </p>
      </div>

      <div v-if="onboardingStore.currency === 'NOK'" class="mb-6">
        <TextFieldWithAutoComplete
          ref="nameInputEl"
          v-model="customer.name"
          :name="$t('customers.company_name')"
          :options="searchResults ?? []"
          @select="handleSelect"
          @search="handleSearch"
        >
          <template #option="{ option }">
            <BrregSummary :company="option as BrregCompany" />
          </template>
        </TextFieldWithAutoComplete>
      </div>
      <div v-else class="mb-8 space-y-4">
        <TextFieldVal
          v-model="customer.name"
          rules="required"
          type="text"
          :label="$t('customers.company_name')"
          :name="$t('customers.company_name')"
        />
        <TextFieldVal
          v-model="customer.company_registration_number"
          rules="required"
          type="text"
          :label="$t('org_number')"
          :name="$t('org_number')"
        />
      </div>
      <div>
        <Button
          v-if="showContinueButton"
          id="submit-company"
          :loading="onboardingStore.loading"
          class="w-full"
          size="large"
          @click="onboardingStore.updateTenantOwner(customer)"
        >
          {{ $t("onboarding.continue_button") }}
        </Button>
      </div>
    </div>
  </HeaderOnboarding>
</template>

<script setup lang="ts">
import type { BrregCompany } from "@/types"
import type { ICustomer } from "@/types/DateTypes"
import { useOnboardingStore } from "@/stores/onboardingStore"

const onboardingStore = useOnboardingStore()
const showContinueButton = ref<boolean>(false)
const searchResults = ref<BrregCompany[]>([])
const customer = ref<Partial<ICustomer>>({
  name: "",
  company_registration_number: "",
  email: "",
  street_address: "",
  zip_code: "",
  city: "",
  municipality: "",
  country_code: "",
  province: "",
  billing_zip_code: "",
  billing_city: "",
  billing_municipality: "",
  
})

if (onboardingStore.currency === "NOK") {
  showContinueButton.value = false
}
else {
  showContinueButton.value = true
}

function searchApiUrl(name: string) {
  return `https://data.brreg.no/enhetsregisteret/api/enheter?navn=${name}&konkurs=false&size=100`
}
function GetApiUrl(orgNumber: string) {
  return `https://data.brreg.no/enhetsregisteret/api/enheter?organisasjonsnummer=${orgNumber}`
}

const handleSearch = async function (searchQuery: string, isPending: Ref<boolean>) {
  if (searchQuery.length >= 3) {
    isPending.value = true
    const result: BrregCompany[]
      = (await (await fetch(searchApiUrl(searchQuery))).json())?._embedded?.enheter ?? []
    searchResults.value = result.map(item => ({
      ...item,
      navn: convertCompanyNameFormat(item.navn),
    }))
    isPending.value = false
  }
}

const handleSelect = async function (companyInfo: BrregCompany) {
  customer.value.company_registration_number = `${companyInfo.organisasjonsnummer}`
  customer.value.name = convertCompanyNameFormat(companyInfo.navn)

  customer.value.street_address = companyInfo.forretningsadresse.adresse[0]
  customer.value.country_code = companyInfo.forretningsadresse.landkode
  customer.value.zip_code = companyInfo.forretningsadresse.postnummer
  customer.value.city = convertCityNameFormat(companyInfo.forretningsadresse.poststed)
  customer.value.municipality = convertCityNameFormat(
    companyInfo.forretningsadresse.kommune,
  )

  customer.value.billing_zip_code = companyInfo.forretningsadresse.postnummer
  customer.value.billing_city = convertCityNameFormat(
    companyInfo.forretningsadresse.poststed,
  )
  customer.value.billing_municipality = convertCityNameFormat(
    companyInfo.forretningsadresse.kommune,
  )

  showContinueButton.value = true
}

function capitalizeFirstLetter(text: string): string {
  if (text === "") { return text }
  return text[0].toUpperCase() + text.substring(1).toLowerCase()
}
function convertCompanyNameFormat(name: string): string {
  const nameFragments = name.split(" ").map(capitalizeFirstLetter)
  const lastIndex = nameFragments.length - 1
  if (["Asa", "As"].includes(nameFragments[`${lastIndex}`])) {
    nameFragments[`${lastIndex}`] = nameFragments[`${lastIndex}`].toUpperCase()
  }
  return nameFragments.join(" ")
}
function convertCityNameFormat(name: string): string {
  const nameFragments = name.split(" ").map((word: string, index: number) => {
    return word.length > 1 || index === 0
      ? capitalizeFirstLetter(word)
      : word.toLowerCase()
  })
  return nameFragments.join(" ").trim()
}
</script>
