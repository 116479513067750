import type { QuoteStatuses } from "@/types"
import type { IProject } from "@/types/DateTypes"
import useFirebase from "@/api/firestore/useFirestore"
import { defineStore } from "pinia"


import { useRoute } from "vue-router"

export const useProjectController = defineStore("projectController", () => {
  const { loadCollection, projectId } = useFirebase()
  const globalStore = useGlobalStore()
  const projectModel = useProjectModel()
  const route = useRoute()

  // State
  const isLoading = ref(false)

  // Watch for route changes to update active project
  watch(
    () => route.params.quoteId,
    (quoteId) => {
      if (typeof quoteId === "string") {
        setActiveProject(quoteId)
      } 
    },
    { immediate: true }
  )

  // Actions
  function setActiveProject(id: string | null) {
    projectModel.activeProjectId = id
    if (id) {
      projectId.value = id // Update Firestore watcher
    }
  }

  async function createProject(project: Partial<IProject>) {
    isLoading.value = true
    try {
      // Implement API call to create a project
      // const newProject = await api.createProject(project)
      // setActiveProject(newProject.id)
    }
    catch (error) {
      console.error("Failed to create project:", error)
      // Handle error
    }
    finally {
      isLoading.value = false
    }
  }

  async function updateProject(projectId: string, updates: Partial<IProject>) {
    isLoading.value = true
    try {
      // Implement API call to update a project
      // await api.updateProject(projectId, updates)
    }
    catch (error) {
      console.error("Failed to update project:", error)
      // Handle error
    }
    finally {
      isLoading.value = false
    }
  }

  async function updateProjectFilter(statuses: QuoteStatuses[]) {
    projectModel.filterStatus = statuses
    isLoading.value = true
    try {
      if (statuses.length > 0) {
        await loadCollection("projects", globalStore.tenant.id, { statuses })
      }
      else {
        await loadCollection("projects", globalStore.tenant.id)
      }
    }
    catch (error) {
      console.error("Failed to update statuses:", error)
    }
    finally {
      isLoading.value = false
    }
  }

  return {
    // State
    isLoading,

    // Actions
    setActiveProject,
    createProject,
    updateProject,
    updateProjectFilter,
  }
})
