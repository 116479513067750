import type { DirectusContext } from "@/types"
import type { IEmployee } from "@/types/DateTypes"
import { t } from "@/localization/i18n"
import { toast } from "@/plugins/toasterPlugin"
import { defineStore } from "pinia"
import { computed, ref } from "vue"

type EmployeeControllerStatus = "viewing" | "editing" | "creating"

export const useEmployeeController = defineStore("employeeController", () => {
  // State
  const activeEmployee = ref<IEmployee | null>(null)
  const isLoading = ref(false)
  const status = ref<EmployeeControllerStatus>("viewing")

  // Computed Properties
  const shouldShowEditEmployeeDrawer = computed(() => {
    return status.value === "editing" || status.value === "creating"
  })

  // Status Management
  function setViewing() {
    status.value = "viewing"
    activeEmployee.value = null
  }

  function setEditing(employee: IEmployee) {
    status.value = "editing"
    activeEmployee.value = employee
  }

  function setCreating() {
    status.value = "creating"
    activeEmployee.value = {
      id: "",
      status: "active",
      job_title: "managing_director",
      role: "employee",
      user: {
        id: "",
        phone_number: "",
        first_name: "",
        last_name: "",
        email: "",
      },
      tenant: "",
      first_name: "",
      last_name: "",
      email: "",
      phone_number: "",
      user_created: "",
      user_updated: "",
      date_created: new Date(),
      date_updated: new Date(),
      invite_message: "",
      access: {
        account_management: false,
        employee_management: false,
        subscription_management: false,
      },
    }
  }

  function cancelCreating() {
    status.value = "viewing"
    activeEmployee.value = null
  }

  function setLoading(loading: boolean) {
    isLoading.value = loading
  }

  // Employee Data Management
  function updateEmployee(employee: Partial<IEmployee>) {
    if (!activeEmployee.value) { return }
    Object.assign(activeEmployee.value, employee)
  }

  async function saveEmployee() {
    if (!activeEmployee.value) { return }

    const globalStore = useGlobalStore()

    // Only allow status change from invited to active for self
    if (activeEmployee.value.id === globalStore.employee?.id) {
      const isActivatingInvite = globalStore.employee?.status === "invited" && 
                                activeEmployee.value.status === "active"
      
      if (!isActivatingInvite && activeEmployee.value.status !== globalStore.employee?.status) {
        toast.error(t("employees.cannot-update-own-role"))
        return
      }
    }

    await api.updateGeneric({
      collection: "employees",
      id: activeEmployee.value.id,
      data: {
        status: activeEmployee.value.status,
        job_title: activeEmployee.value.job_title,
        access: activeEmployee.value.access,
        role: activeEmployee.value.role,
      },
    } as unknown as DirectusContext<"employees">)
    setViewing()
    toast.success(t("shared.confirmations.success"))
  }

  async function createEmployee() {
    if (!activeEmployee.value) { return }
    const globalStore = useGlobalStore()

    if (!activeEmployee.value.user.phone_number) {
      toast.error(t("employees.phone_number_required"))
      return
    }

    if (globalStore.tenant.id) {
      await api.createGeneric({
        collection: "employees",
        data: {
          phone_number: activeEmployee.value.user.phone_number,
          tenant: globalStore.tenant.id,
          status: "invited",
          invite_message: t("employees.sms_invite_message", {
            name: globalStore.user.first_name,
            companyName: globalStore.tenant.name,
            iosLink: "https://apps.apple.com/no/app/tredco/id1670203376",
            androidLink: "https://play.google.com/store/apps/details?id=app.tredco",
          }),
        },
      } as unknown as DirectusContext<"employees">)
      toast.success(t("employees.invite_employee_success"))
      setViewing()
    }
  }

  // Employee Status Actions
  async function deactivateEmployee() {
    if (!activeEmployee.value) { return }
    const globalStore = useGlobalStore()
    if (activeEmployee.value.id === globalStore.employee?.id) {
      toast.error(t("employees.cannot-deactivate-yourself"))
      return
    }
    updateEmployee({ status: "inactive" })
    await saveEmployee()
    setViewing()
  }

  async function activateEmployee() {
    updateEmployee({ status: "active" })
    await saveEmployee()
    setViewing()
  }

  async function archiveEmployee() {
    if (!activeEmployee.value) { return }
    const globalStore = useGlobalStore()
    if (activeEmployee.value.id === globalStore.employee?.id) {
      toast.error(t("employees.cannot-deactivate-yourself"))
      return
    }
    updateEmployee({ status: "archived" })
    await saveEmployee()
    setViewing()
  }

  // Profile & Redirection
  function shouldRedirectToProfile() {
    const dataStore = useDataStore()
    const globalStore = useGlobalStore()

    if (globalStore.isLoggedIn && globalStore.employee) {
      const currentEmployee = dataStore.employeesById[globalStore.employee?.id]
      return (
        currentEmployee?.status === "invited" || currentEmployee?.status === "inactive"
      )
    }
    return false
  }

  function redirectEmployeeToAcceptInvite() {
    return shouldRedirectToProfile()
  }

  function handleAccessUpdate(updatedOptions: { id: string, selected: boolean }) {
    if (!activeEmployee.value) { return }
    let access = activeEmployee.value.access || {
      account_management: false,
      employee_management: false,
      subscription_management: false,
    }

    access = {
      ...access,
      [updatedOptions.id]: updatedOptions.selected,
    }
    updateEmployee({ access })
  }

  return {
    // State
    activeEmployee,
    isLoading,
    status,

    // Computed
    shouldShowEditEmployeeDrawer,

    // Status Management
    setViewing,
    setEditing,
    setCreating,
    setLoading,
    cancelCreating,

    // Employee Data Management
    updateEmployee,
    saveEmployee,
    createEmployee,

    // Employee Status Actions
    deactivateEmployee,
    activateEmployee,
    archiveEmployee,

    // Profile & Redirection
    redirectEmployeeToAcceptInvite,
    shouldRedirectToProfile,
    handleAccessUpdate,
  }
})
