import type { BrregCompany, User } from "@/types"
import type { ITenant, ICustomer } from "@/types/DateTypes"
import api from "@/api"
import { useGlobalStore } from "@/stores/globalStore"
import { useOnboardingStore } from "@/stores/onboardingStore"
import { defineStore } from "pinia"

interface TenantSettingsState {
  isLoading: boolean
  error: boolean
  selectedCompany: BrregCompany | null
  onBoardingStore: ReturnType<typeof useOnboardingStore>
  globalStore: ReturnType<typeof useGlobalStore>
  user: User | null
  inProgress: boolean
  logoUrl: string
  colorPalette: string[]
  selectedColor: string
  fileEvent: Event | null
  tenant: Partial<ITenant>
  owner: ICustomer | undefined
  isUpdatingProfile: boolean
  searchStatus: SearchStatus
  searchResults: BrregCompany[] | undefined
  defaultSearchSize: number
}
enum SearchStatus {
  SelectCompany = "SelectCompany",
  Loading = "Loading",
  None = "None",
}

export const useTenantStore = defineStore("tenant", {
  state: (): TenantSettingsState => ({
    isLoading: false,
    error: false,
    selectedCompany: null,
    onBoardingStore: useOnboardingStore(),
    user: null,
    tenant: {},
    inProgress: false,
    owner: undefined,
    logoUrl: "",
    colorPalette: ["#009688", "#FF6F61", "#FFC107", "#8E24AA"],
    selectedColor: "",
    fileEvent: null,
    globalStore: useGlobalStore(),
    isUpdatingProfile: false,
    searchStatus: SearchStatus.None,
    searchResults: undefined,
    defaultSearchSize: 12,
  }),
  actions: {
    async updateProfile(values: {
      email?: string
      first_name: string
      last_name: string
    }) {
      try {
        this.isUpdatingProfile = true
        await api.users.updateMe({
          email: values.email,
          first_name: values.first_name,
          last_name: values.last_name,
        })
        Object.assign(this.globalStore.user, values)
        this.isUpdatingProfile = false
        console.log("Variable updated successfully!")
      }
      catch (error) {
        console.log("Error updating user fields", error)
      }
      finally {
        this.isUpdatingProfile = false
      }
    },
    async updateInvoiceColor(color: string) {
      this.isLoading = true
      if (this.globalStore.tenant?.id) {
        await api.users.updateMyTenant(this.globalStore.tenant.id, {
          invoice_color: color,
        })
        await this.refreshTenant()
      }
      this.isLoading = false
    },
    async updateTenant(values: Partial<ITenant>) {
      this.inProgress = true
      if (this.globalStore.tenant?.id) {
        await api.users.updateMyTenant(this.globalStore.tenant.id, values)
        await this.refreshTenant()
      }
      this.inProgress = false
    },
    async refreshTenant() {
      this.globalStore.user = await api.users.getMe()
      this.globalStore.setTenant()
    },
    async updateTenantOwner(owner: ICustomer) {
      const wantedProps = [
        "name",
        "company_registration_number",
        "email",
        "phone_number",
        "street_address",
        "zip_code",
        "city",
        "municipality",
        "country_code",
        "province",
        "billing_country_code",
        "billing_zip_code",
        "billing_city",
        "billing_municipality",
      ] as const
      type WantedProp = (typeof wantedProps)[number]

      const propsToUpdate: Pick<ICustomer, WantedProp> = {}

      const setProp = <T extends WantedProp>(prop: T) => {
        propsToUpdate[prop] = owner[prop]
      }
      wantedProps.forEach(prop => setProp(prop))

      try {
        const tenant = await api.users.updateMyTenant(this.globalStore.tenant.id, {
          ...propsToUpdate,
        })
        this.globalStore.setTenant(tenant.id)
        console.log("Id", this.globalStore.tenant.id)
      }
      catch (error) {
        console.log(error)
      }
    },
    async updateTenantInvoiceSettings(values: Partial<ITenant>) {
      try {
        const tenantId = this.globalStore.tenant.id
        if (tenantId) {
          this.tenant = await api.users.updateMyTenant(tenantId, values)
          Object.assign(this.globalStore.tenant, values)
        }
      }
      catch (error) {
        console.log(error)
      }
    },
    changeCompany(name?: string, regNumber?: string) {
      if (!this.owner) { throw new Error("owner not defined") }
      this.owner.name = name ?? ""
      this.owner.company_registration_number = regNumber ?? ""
    },

    async searchCompany(searchQuery: string, size?: number) {
      this.isLoading = true
      if (searchQuery.length >= 3) {
        this.searchStatus = SearchStatus.Loading
        console.log(this.searchStatus)
        const result: BrregCompany[]
          = (await (await fetch(this.searchApiUrl(searchQuery, size))).json())?._embedded?.enheter ?? []

        this.searchResults = result.map(item => ({
          ...item,
          navn: this.convertCompanyNameFormat(item.navn),
        }))
        this.isLoading = false
        // console.log(this.searchResults)
        if (this.searchResults.length > 0) {
          this.searchStatus = SearchStatus.SelectCompany
        }
        else {
          this.searchStatus = SearchStatus.None
        }
        console.log("done!")
        console.log("selected company", this.selectedCompany)
      }
    },

    convertCompanyNameFormat(name: string): string {
      const nameFragments: string[] = name.split(" ").map(this.capitalizeFirstLetter)
      const lastIndex = nameFragments.length - 1
      if (["Asa", "As"].includes(nameFragments[`${lastIndex}`])) {
        nameFragments[`${lastIndex}`] = nameFragments[`${lastIndex}`].toUpperCase()
      }
      return nameFragments.join(" ")
    },
    capitalizeFirstLetter(text: string): string {
      if (text === "") { return text }
      return text[0].toUpperCase() + text.substring(1).toLowerCase()
    },
    searchApiUrl(compName: string, size?: number): string {
      if (!size) { size = this.defaultSearchSize }
      return `https://data.brreg.no/enhetsregisteret/api/enheter?navn=${compName}&konkurs=false&size=${size}`
    },
  },
  getters: {
    async getCompanyLogoUrl(): Promise<string> {
      try {
        const logo = this.globalStore.tenant.logo
        if (logo) {
          return `${api.client.url}/assets/${primaryKey(logo)}?access_token=${this.globalStore.token}`
        }
      }
      catch (error) {
        console.error("Error fetching the logo URL:", error)

        return ""
      }

      return ""
    },

    async getTenantInvoiceColor(): Promise<string | null> {
      const invoiceColor = this.globalStore.tenant.invoice_color
      return invoiceColor
    },
  },
})
