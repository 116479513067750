<template>
  <PageLayout>
    <template #header>
      <TopNav
        :saving="projectStore.loading"
        :title="$t('send_quote')"
        :show-additiol-options="false"
        type="edit"
        @back="router.back()"
      />
    </template>
    <template #default>
      <QuoteSettingSummary
        :title="$t('recipients')"
        :sub-title="quoteEmailStore.emailData.email.join(', ')"
        :required="true"
      >
        <QuoteSettingsRecipient />
      </QuoteSettingSummary>

      <div>
        <div class="px-3 py-2 mt-4 space-y-3 text-sm border rounded-2xl bg-gray-50">
          <p>{{ $t("hello") }},</p>

          <TextArea
            v-model="quoteEmailStore.emailData.body.p_1"
            class="w-full"
            :without-min-height="true"
            :rows="5"
          />
          <TextArea
            v-model="quoteEmailStore.emailData.body.p_2"
            class="w-full"
            :without-min-height="true"
            :rows="5"
          />
          <p class="pb-24">
            {{ $t("best_regards") }},
            <br>
            {{ projectStore.globalStore.tenant.name }}
            <br>
            {{ $t("email_note") }}
          </p>
        </div>
        <Button v-if="!mobile" type="primary" class="my-2" size="large" @click="sendEmail">
          {{ $t("send") }}
        </Button>
      </div>
    </template>
    <template #footer-mobile>
      <Button type="primary" class="w-full mr-2" size="large" @click="sendEmail">
        {{ $t("send") }}
      </Button>
    </template>
  </PageLayout>
</template>

<script setup lang="ts">
import type { Toaster } from "@/types"
import type { IProject, ITenant } from "@/types/DateTypes"
import api from "@/api"
import { t } from "@/localization/i18n"
import router from "@/router"
import { useProjectStore } from "@/stores/projectStore"
import { useQuoteEmailStore } from "@/stores/quoteEmailStore"

const route = useRoute()
const projectStore = useProjectStore()
const quoteId = route.params.quoteId as string
const project = ref<IProject | undefined>(undefined)
const tenant = ref<ITenant>()
const toast = inject<Toaster>("toast")
const quoteEmailStore = useQuoteEmailStore()

const globalStore = useGlobalStore()

init()

async function init() {
  await projectStore.setProject(quoteId)

  const tenantId = localStorage.getItem("tenant") as string
  tenant.value = await api.users.getMyTenant(tenantId)

  project.value = projectStore.project as IProject

  if (quoteEmailStore.projectId !== project.value.id) { quoteEmailStore.initState(tenant.value, project.value) }
}

async function sendEmail() {
  if (!project.value || !projectStore.project || !tenant.value) {
    return
  }
  await quoteEmailStore.sendEmail(tenant.value, globalStore.token as string)
  projectStore.project.quote_status = "pending"
  await projectStore.updateProject()
  toast?.success(t("invoice_email_sent"))
  await router.push({ name: "quote", params: { quoteId: project.value.id } })
}
const mobile = inject("isPhone") as Ref<boolean>
</script>
