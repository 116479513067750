<template>
  <div>
    <a
      v-if="pdfUrl"
      class="flex gap-x-1 font-bold text-link-static underline"
      :href="pdfUrl"
      target="_blank"
      @click="pdfUrl = undefined"
    >
      {{ $t("quote.show_pdf") }}
    </a>
    <template v-else>
      <Button type="secondary" :loading="pdfLoading" @click="downloadPDF">
        <p>{{ $t("quote.generate_pdf") }}</p>
      </Button>
    </template>
  </div>
</template>

<script setup lang="ts">
import type { User } from "@/types"
import type { IProject, ITenant } from "@/types/DateTypes"
import api from "@/api"

const props = defineProps<{
  project: IProject
  tenant: ITenant
  user: User
  accessToken: string
}>()

const pdfUrl = ref<string>()
const pdfLoading = ref(false)

async function downloadPDF() {
  if (!props.project || !props.tenant) {
    return
  }
  pdfLoading.value = true
  pdfUrl.value = await api.quotes.downloadPdf(props.project, props.tenant, props.user.id, props.accessToken)
  pdfLoading.value = false
}
</script>
