<template>
  <PageLayout>
    <template #header>
      <div v-if="projectStore.status === 'edit-pick-from-template'" class="flex">
        <IconArrowLeft @click="goBack" />
      </div>
      <div class="flex items-center justify-between">
        <h2 class="text-heading-2">
          {{ $t("menu-mobile.templates") }}
        </h2>
        <Button
          v-if="!mobile"
          size="medium"
          icon="plus"
          :loading="postStore.creatingPostTemplate"
          @click="newPostTemplate()"
        >
          {{ $t("templates.new_template") }}
        </Button>
      </div>
      <SearchInputV2
        v-if="postStore.postTemplates.length > 7"
        v-model="searchTerm"
        class="mt-4"
        @reset="() => (searchTerm = '')"
      />
    </template>
    <template #default>
      <div>
        <ol v-if="!projectStore.creatingPost" class="mb-32">
          <li v-for="post in filteredPostTemplates" :key="post.id">
            <PostSummary
              v-if="!post.isFiller"
              :id="post.id"
              :key="post.id"
              :name="post.name"
              :qty="post.qty.toString()"
              :unit="post.unit"
              :unit-price="post.unit_price.toString()"
              :post-id="post.id"
              :is-template="true"
              @click="post.id && gotoPost(post)"
            />
          </li>
        </ol>
        <AtomSpinner
          v-if="projectStore.creatingPost"
          class="left-0"
          :size="20"
          color="blue"
        />
        <TemplatesPlaceholder
          v-if="postStore.postTemplates.length && postStore.postTemplates[0].isFiller"
        />
      </div>
      <div v-if="mobile" class="fixed inset-x-0 w-full px-4 bottom-20 pb-safe">
        <Button
          icon="plus"
          class="w-full"
          size="large"
          :loading="postStore.creatingPostTemplate"
          @click="newPostTemplate()"
        >
          {{ $t("templates.new_template") }}
        </Button>
      </div>
    </template>
  </PageLayout>
</template>

<script setup lang="ts">
import type { IPost } from "@/types/DateTypes"
import { usePostStore } from "@/stores/postStore"
import { useProjectStore } from "@/stores/projectStore"

const postStore = usePostStore()
const projectStore = useProjectStore()

const router = useRouter()
const showOptions = ref(false)
provide<Ref<boolean>>("showOptions", showOptions)

const activePostId = ref<string | undefined>(undefined)
provide<Ref<string | undefined>>("activePostId", activePostId)
watch(activePostId, async (newPostId) => {
  if (newPostId) {
    await postStore.loadPost(newPostId)
  }
})

async function gotoPost(post: IPost) {
  if (projectStore.status === "edit-pick-from-template") {
    const postFromTemplate = await postStore.convertTemplateToPost(post)
    postStore.status = "new"
    projectStore.status = "edit"
    await router.push({
      name: "post",
      params: { postId: postFromTemplate.id },
    })
  }
  else {
    if (!mobile.value) { return }
    postStore.post = post
    postStore.status = "edit"
    await router.push({
      name: "post",
      params: { postId: post.id },
    })
  }
}
async function newPostTemplate() {
  const postTemplate = await postStore.createPostTemplate()
  if (projectStore.status === "edit-pick-from-template") {
    postStore.addTemplateToCurrentProject = true
  }
  postStore.status = "new"
  await router.push({
    name: "post",
    params: { postId: postTemplate.id },
  })
}

async function goBack() {
  router.go(-1)
}
const mobile = inject("isPhone") as Ref<boolean>
const showBottomNav = inject("showBottomNav") as Ref<boolean>
onMounted(() => {
  if (projectStore.status !== "edit-pick-from-template") {
    showBottomNav.value = true
  }
  else {
    showBottomNav.value = false
  }
})

onBeforeMount(async () => {
  await postStore.getAllPostTemplates()
})

onUnmounted(() => {
  showBottomNav.value = false
})

const searchTerm = ref("")

const filteredPostTemplates = computed<IPost[]>(() => {
  let filtered = postStore.postTemplates

  if (searchTerm.value !== "") {
    const q = searchTerm.value.trim().toLocaleLowerCase()
    filtered = filtered.filter((p) => {
      return p.name?.toLowerCase().includes(q)
    })
  }

  return filtered
})
</script>
