<template>
  <div
    class="flex max-h-24 min-h-12 min-w-12 max-w-24 cursor-pointer items-center justify-center overflow-clip rounded-full border-2 hover:opacity-70"
    :style="`border-color: ${borderColor}`"
    @click="updateImage()"
  >
    <AtomSpinner v-if="isLoading" :size="16" />
    <template v-else>
      <img v-if="src" :src="src" class="object-cover">
      <slot v-else />
    </template>
  </div>
</template>

<script setup lang="ts">
import type { DirectusContext } from "@/types"
import { captureImage, urlToFile, uploadFileToServer } from "@/utils/fileUtils"
import { useGlobalStore } from "@/stores/globalStore"

const props = defineProps({
  src: {
    type: String,
    default: "",
  },
  allowEdit: {
    type: Boolean,
    default: true,
  },
  borderColor: {
    type: String,
    default: "",
  },
  updateImageContex: {
    type: Object as PropType<DirectusContext>,
    default: undefined,
  },
})

const emit = defineEmits<{
  (e: 'imageUpdated', fileId: string): void
  (e: 'error', message: string): void
}>()

const globalStore = useGlobalStore()
const isLoading = ref(false)

async function updateImage() {
  if (!props.allowEdit || !props.updateImageContex) {
    return
  }

  try {
    const image = await captureImage()
    if (!image?.webPath) return

    const file = await urlToFile(image.webPath)
    if (!file) {
      emit('error', 'Failed to process image')
      return
    }

    isLoading.value = true
    const fileId = await uploadFileToServer(
      file,
      globalStore.tenant.id,
      props.updateImageContex
    )
    
    if (fileId) {
      emit('imageUpdated', fileId)
    } else {
      emit('error', 'Failed to upload image')
    }
  } catch (error) {
    console.error('Error updating image:', error)
    emit('error', 'An error occurred while updating the image')
  } finally {
    isLoading.value = false
  }
}
</script>
