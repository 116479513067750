import type { ICustomer } from "@/types/DateTypes"
import { client, withRetry } from "@/api/directus"
import { createItem, readItem, updateItem } from "@directus/sdk"

async function updateCustomer(customerId: string, update: ICustomer): Promise<ICustomer> {
  return (await client.request(updateItem("customers", customerId, update))) as ICustomer
}

async function createCustomer(customer: ICustomer): Promise<ICustomer> {
  return (await client.request(createItem("customers", customer))) as ICustomer
}

export const customers = {
  updateCustomer,
  createCustomer,
}
