<template>
  <div v-if="project && tenant && directusContext">
    <div class="p-4">
      <p class="pb-3 text-label-2 text-color-label-4">
        {{ $t("pdf_settings") }}
      </p>
      <div class="py mb-4 rounded-xl bg-1-static">
        <div class="flex justify-between border-b py-2">
          <p>{{ $t("show_price") }}</p>
          <CheckBox
            v-model="project.visible_post_price"
            :directus-context="{
              ...directusContext,
              property: 'visible_post_price',
            }"
          />
        </div>
        <div class="flex justify-between border-b py-2">
          <p>{{ $t("show_post_details") }}</p>
          <CheckBox
            v-model="project.visible_post_description"
            :directus-context="{
              ...directusContext,
              property: 'visible_post_description',
            }"
          />
        </div>
        <div class="flex justify-between border-b py-2">
          <p>{{ $t("show_quantities") }}</p>
          <CheckBox
            v-model="project.visible_post_qty"
            :directus-context="{
              ...directusContext,
              property: 'visible_post_qty',
            }"
          />
        </div>
        <div class="flex justify-between border-b py-2">
          <p>{{ $t("show_photos") }}</p>
          <CheckBox
            v-model="project.visible_post_photos"
            :directus-context="{
              ...directusContext,
              property: 'visible_post_photos',
            }"
          />
        </div>
        <div class="py-2">
          <SelectBox
            translation-path="post_image_sizes"
            :selected-value="project.post_image_size"
            :label="$t('post_image_size')"
            @select="
              value => {
                if (project) {
                  project.post_image_size = value
                  projectStore.updateProject()
                }
              }
            "
          />
        </div>
        <div class="py-2">
          <TextFieldVal
            v-model="project.invoice_due_in_days"
            name="invoice_due_in_days"
            :label="$t('payment_terms')"
            type="number"
            text-size="normal"
            :directus-context="directusContext"
            directus-property="invoice_due_in_days"
          />
        </div>

        <div class="flex py-2">
          <TextFieldVal
            v-model="project.date_issued"
            name="date_issued"
            :label="$t('project.date_issued')"
            type="date"
            class="mr-1 w-1/2"
            :directus-context="directusContext"
            directus-property="date_issued"
          />
          <TextFieldVal
            v-model="project.date_expire"
            name="date_expire"
            :label="$t('project.date_expire')"
            type="date"
            class="ml-1 w-1/2 overflow-hidden"
            :directus-context="directusContext"
            directus-property="date_expire"
          />
        </div>
      </div>
      <div class="space-y-2">
        <Button class="w-full" type="secondary" @click="router.push({ name: 'logo' })">{{ $t("company-settings.logo_colors") }}</Button>
        <Button class="w-full" type="secondary" @click="router.push({ name: 'certificates' })">{{ $t("company-settings.certificates.title") }}</Button>
      </div>
    </div>
  </div>
  <AtomSpinner v-else />
</template>

<script setup lang="ts">
import type { DirectusContext } from "@/types"
import type { IProject, ITenant } from "@/types/DateTypes"
import { getSecondaryColor } from "@/composables/color"
import { useProjectStore } from "@/stores/projectStore"
import { useGlobalStore } from "@/stores/globalStore"
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import Button from '@/components/ui/buttons/Button.vue'

const emit = defineEmits(["back"])
const projectStore = useProjectStore()
const globalStore = useGlobalStore()
const router = useRouter()
const project = ref<IProject>()
const tenant = ref<ITenant>()
const directusContext = ref<DirectusContext | undefined>(undefined)
const colors = ref({ primary: "#0000ff", secondary: "#8888ff", customized: false })

init()

async function init() {
  project.value = projectStore.project as IProject
  directusContext.value = projectStore.directusContext as DirectusContext
  tenant.value = globalStore.tenant
  colors.value = calculateColor(tenant.value)
}

function calculateColor(tenant?: ITenant) {
  if (!tenant) {
    return { primary: "#0000ff", secondary: "#8888ff", customized: false }
  }
  const primary = tenant.invoice_color ?? "#0000ff"
  const secondary = getSecondaryColor(primary)
  return { primary, secondary, customized: !!tenant.invoice_color }
}
</script>
