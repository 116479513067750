import type { User } from "@/types"
import type { ITenant } from "@/types/DateTypes"
import { App } from "@capacitor/app"
import { Device } from "@capacitor/device"
import config from "../../capacitor.config"

export async function initializePendo(user: User, tenant: ITenant): Promise<void> {
  const pendo = (window as any).pendo
  if (pendo === undefined) { return }

  // Don't initialize Pendo for developers or non-production tenants
  if (!tenant.is_production || user.developer) {
    // If pendo was previously initialized, shutdown tracking
    if (typeof pendo.shutdown === "function") {
      pendo.shutdown()
    }
    return
  }

  // Initialize default device and app info
  let deviceInfo = {
    platform: "web",
    model: "browser",
    osVersion: navigator.userAgent,
    isVirtual: true,
  }
  let appInfo = {
    version: config.plugins?.CapacitorUpdater?.version ?? undefined,
  }

  // Only try to get native device info if we're in a native environment
  try {
    deviceInfo = await Device.getInfo()
    appInfo = await App.getInfo()
  }
  catch (error) {
    console.debug("Running in web environment, using default device info")
  }

  pendo.initialize({
    visitor: {
      id: user.id,
      email: user.email,
      full_name: user.first_name,
      // Add device-specific information for better mobile tracking
      platform: deviceInfo.platform,
      device_model: deviceInfo.model,
      os_version: deviceInfo.osVersion,
      app_version: appInfo.version,
      is_native: deviceInfo.isVirtual === false,
    },

    account: {
      id: tenant.id,
      name: tenant.name,
      is_paying: tenant.subscription_status,
      is_production: tenant.is_production,
    },

    // Add specific configuration for mobile/hybrid apps
    enabledNavigationTiming: true, // Helps track performance in WebViews
    enabledUserTracking: true, // Ensures user tracking works in WebViews
    excludeAllText: false, // Needed for proper mobile text capture
    sanitizeUrl: (url: string) => {
      // Handle both native and web URLs to ensure proper page tracking
      return url
        .replace(/capacitor:\/\/localhost/, "https://tredco.app")
        .replace(/localhost/, "tredco.app")
    },
  })

  // Track native app launch
  pendo.track("app_launched", {
    platform: deviceInfo.platform,
    model: deviceInfo.model,
    os_version: deviceInfo.osVersion,
    app_version: appInfo.version,
  })
}
