<template>
  <div
    class="w-full pt-20 rounded-t-3xl"
    :style="{ backgroundColor: tenantStore.selectedColor }"
  >
    <LogoPhoto :logo-url="tenantStore.logoUrl" />

    <div class="h-full">
      <div class="py-4 rounded-t-3xl bg-1-static text-label-1">
        <p class="flex items-center justify-center text-label-2">
          {{ $t("onboarding.logo_1") }}
        </p>
      </div>
      <hr>
      <div class="h-full p-6 bg-1-static pb-28">
        <LogoUpload @file-selected="handleFileSelected" />
        <LogoColorPicker />
        <div class="flex gap-4">
          <Button
            id="submit-logo"
            :loading="isLoading"
            class="w-full mt-12"
            size="large"
            @click="saveLogoAndPrimaryColor"
          >
            {{ buttonTitle }}
          </Button>
          <Button
            id="cancel-logo"
            class="w-full mt-12"
            type="secondary"
            size="large"
            @click="() => emit('secondryClicked')"
          >
            {{ secondaryButtonTitle }}
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { DirectusContext, Toaster } from "@/types"
import { useGlobalStore } from "@/stores/globalStore"
import { useTenantStore } from "@/stores/tenantSettingsStore"
import { getColorPalette } from "@/utils/companyUtils"
import { uploadFileToServer } from "@/utils/fileUtils"

interface Props {
  buttonTitle: string
  secondaryButtonTitle: string
}

withDefaults(defineProps<Props>(), {
  buttonTitle: "save",
  secondaryButtonTitle: "cancel",
})

const emit = defineEmits(["updated", "secondryClicked"])
const globalStore = useGlobalStore()
const tenantStore = useTenantStore()
const logoUrl = ref("")
const selectedFile = ref<File | null>(null)
const isLoading = ref(false)
const toast = inject<Toaster>("toast") as Toaster

async function handleFileSelected(file: File) {
  selectedFile.value = file
}

async function saveLogoAndPrimaryColor(): Promise<void> {
  if (tenantStore.selectedColor.toLocaleLowerCase() === "#163BAE".toLocaleLowerCase()) {
    tenantStore.error = true
    toast.error(t("onboarding.logo_8"))
    return
  }

  if (!selectedFile.value) {
    toast.error(t("onboarding.logo_no_file"))
    return
  }

  isLoading.value = true
  try {
    const directusContext: DirectusContext = {
      collection: "tenants",
      id: globalStore.tenant.id,
      property: "logo",
    }

    await uploadFileToServer(selectedFile.value, globalStore.tenant.id, directusContext)
    await tenantStore.updateInvoiceColor(tenantStore.selectedColor)
    globalStore.tenant.invoice_color = tenantStore.selectedColor
    emit("updated")
    tenantStore.error = false
  } catch (error) {
    console.error("Error saving logo:", error)
    toast.error(t("onboarding.logo_error"))
  } finally {
    isLoading.value = false
  }
}

onUpdated(() => {
  try {
    const colorHex = tenantStore.selectedColor?.hex.toLowerCase()
    tenantStore.selectedColor = colorHex
  }
  catch (error) {
    console.log()
  }
})

onMounted(async () => {
  try {
    logoUrl.value = await tenantStore.getCompanyLogoUrl
    const img = new Image()
    img.src = logoUrl.value
    img.crossOrigin = "Anonymous"
  }
  catch (error) {
    console.error(error)
  }
})
</script>
