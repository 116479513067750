<template>
  <div v-if="fileIds.length !== 0">
    <ul class="flex" :class="[{ 'flex-wrap': wrapPhotos }]">
      <li
        v-for="id in fileIds"
        :key="id"
        class=" overflow-hidden p-2"
        :class="[
          { 'my-1': wrapPhotos },
          sizeClasses[size],
        ]"
      >
       <div class="relative shadow-hovering rounded-xl">
        <img
          :src="imageUrls[id]"
          alt="Thumbnail"
          class="h-full w-full object-cover rounded-xl"
          @click="handleImageClick(id)"
        >
        <button
          v-if="allowDelete"
          class="absolute -left-1 -top-1 rounded-full text-white bg-gray-500/60 p-1 hover:bg-gray-800/80"
          @click.stop="handleDelete(id)"
        >
          <XMarkIcon class="h-2.5 w-2.5" />
        </button>
       </div>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { useImageStore } from "@/stores/imageStore"
import { useGlobalStore } from "@/stores/globalStore"
import { fileUrl } from "@/utils/fileUrl"
import { XMarkIcon } from "@heroicons/vue/24/solid"

type ImageSize = 'small' | 'medium' | 'large'

interface Props {
  fileIds: string[]
  wrapPhotos?: boolean
  allowDelete?: boolean
  editMode?: boolean
  size?: ImageSize
}

const props = withDefaults(defineProps<Props>(), {
  wrapPhotos: false,
  allowDelete: false,
  editMode: false,
  size: 'medium',
})

const emit = defineEmits<{
  (e: 'imageClick', id: string): void
  (e: 'deleteImage', id: string): void
}>()

const globalStore = useGlobalStore()
const imageStore = useImageStore()
const imageUrls = ref<{ [id: string]: string }>({})

const sizeClasses = {
  small: 'h-12 w-12 min-w-[48px]',
  medium: 'h-16 w-16 min-w-[64px]',
  large: 'h-24 w-24 min-w-[96px]',
} as const

async function handleImageClick(id: string) {
  if (props.editMode) {
    imageStore.status = "edit"
    imageStore.imageId = id
    imageStore.hydrateStoreWithRemoteImage(await fileUrl(id, globalStore.token))
  }
  emit('imageClick', id)
}

async function handleDelete(id: string) {
  emit('deleteImage', id)
}

// Load image URLs when fileIds change
watch(
  () => props.fileIds,
  async (newIds) => {
    for (const id of newIds) {
      imageUrls.value[id] = await fileUrl(id, globalStore.token, true)
    }
  },
  { immediate: true },
)
</script>
