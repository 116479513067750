import type {  User } from "@/types"
import type { ICustomer, ITenant, IPost } from "@/types/DateTypes"

const defaultUser: User = {
  id: "loadingUser12345",
  first_name: "Loading",
  last_name: "...",
  email: "loading-user@example.com",
  phone_number: "000-000-0000",
  active_tenant: "loadingActiveTenant12345",
  developer: false,
  role: {
    id: "loadingRole12345",
    name: "Default Role",
  },
  tenants: [
    {
      id: "0",
      status: "active",
      tenant: {
        id: "loading12345",
        name: "Loading...",
        email: "loading@example.com",
        street_address: "Loading...",
        zip_code: "00000",
        city: "Loading...",
        country_code: "XX",
        company_registration_number: "0000000",
        invoice_due_in_days: 0,
        quote_expire_in_days: 0,
        visible_vat: false,
        visible_post_description: false,
        visible_post_price: false,
        visible_post_qty: false,
        logo: "default_logo_path",
        invoice_color: "#edf0f3ff",
        is_production: true,
        subscription_status: "null",
        subscription_id: "loadingSubscription12345",
        subscription_users: 0,
        subscription_email: "loading-subscription@example.com",
        subscription_limit_access: false,
        subscription_trial_expire_at: new Date().toISOString(),
        terms: "Loading...",
      },
    },
  ],
}

const defaultPostsValue: IPost  = {
  id: "",
  status: "",
  date_created: new Date(),
  name: "...",
  qty: 0,
  unit: "pcs",
  description: "",
  unit_price: 0,
  is_template: false,
  phase: "",
  tenant: "",
  pictures: [],
  isFiller: true,
  sort: 0,
}

const defaultCustomerValue: ICustomer = {
  id: "loading12345",
  status: "published",
  user_created: defaultUser.id,
  date_created: new Date(),
  user_updated: defaultUser.id,
  date_updated: new Date(),
  name: "Loading...",
  email: "loading@example.com",
  street_address: "Loading...",
  zip_code: "00000",
  city: "Loading...",
  municipality: "Loading...",
  province: "Loading...",
  phone_number: "000-000-0000",
  country_code: "XX",
  company_registration_number: "0000000",
  billing_country_code: "XX",
  billing_zip_code: "00000",
  billing_municipality: "Loading...",
  billing_street_address: "Loading...",
  billing_city: "Loading...",
  billing_province: "Loading...",
  type: "company",
  currency: "NOK",
  projects: [],
  tenant: "0",
}

const emptyCustomerValue: ICustomer = {
  id: "",
  status: "draft",
  company_registration_number: "",
  name: "",
  email: "",
  phone_number: "",
  street_address: "",
  zip_code: "",
  city: "",
  municipality: "",
  province: "",
  country_code: "",
  billing_street_address: "",
  billing_country_code: "",
  billing_zip_code: "",
  billing_city: "",
  billing_municipality: "",
  billing_province: "",
  type: "company",
  currency: "NOK",
  projects: [],
  tenant: "",
  user_created: "",
  date_created: new Date(),
  user_updated: "",
  date_updated: new Date(),
}

const defaultTenantValue: ITenant = {
  id: "loading12345",
  name: "Loading...",
  email: "loading@example.com",
  street_address: "Loading...",
  zip_code: "00000",
  city: "Loading...",
  country_code: "XX",
  company_registration_number: "0000000",
  invoice_due_in_days: 0,
  quote_expire_in_days: 0,
  visible_vat: false,
  visible_post_description: false,
  visible_post_price: false,
  visible_post_qty: false,
  logo: "default_logo_path",
  invoice_color: "#edf0f3ff",
  is_production: true,
  subscription_status: "null",
  subscription_id: "loadingSubscription12345",
  subscription_users: 0,
  subscription_email: "loading-subscription@example.com",
  subscription_trial_expire_at: new Date().toISOString(),
  subscription_limit_access: false,
  terms: "Loading...",
  phone_number: "",
  type: "prod",
  currency: "NOK",
  note: null,
  payment_plan: [],
  vat_registered: false,
  certifications: [],
  metrics: {
    projects: {
      total: {
        count: 0,
        subtotal_amount: 0,
        invoiced_amount: 0,
      },
      by_status: {
        draft: {
          count: 0,
          subtotal_amount: 0,
        },
        pending: {
          count: 0,
          subtotal_amount: 0,
        },
        accepted: {
          count: 0,
          subtotal_amount: 0,
        },
        done: {
          count: 0,
          subtotal_amount: 0,
        },
        rejected: {
          count: 0,
          subtotal_amount: 0,
        },
        expired: {
          count: 0,
          subtotal_amount: 0,
        },
      },
    },
  },
  user_created: "",
  user_updated: "",
  date_created: new Date(),
  date_updated: new Date()
}

export default {
  defaultPostsValue,
  defaultCustomerValue,
  emptyCustomerValue,
  defaultUser,
  defaultTenantValue,
}
