<template>
  <div class="flex items-center">
    <div class="flex-none mr-4">
      <div v-if="props.type === 'new'" @click="emit('exit')">
        <IconButton />
      </div>
      <div v-if="props.type === 'edit'" @click="emit('back')">
        <IconButton back />
      </div>
    </div>
    <slot />
    <div class="absolute left-1/2 -translate-x-1/2">
      <p class="text-label-bold-1 whitespace-nowrap">
        {{ props.title }}
      </p>
    </div>
    <div class="flex-1"></div>
    <Button
      v-if="props.type === 'new' && !hideSaveButton"
      class="mr-3"
      size="small"
      type="primary"
      @click="e => emit('save')"
    >
      {{ $t("shared.actions.save") }}
    </Button>

    <div v-if="props.type === 'edit' || props.type === 'none'" class="flex items-center">
      <div :class="{ 'mr-8': saving }">
        <SaveState :is-loading="saving" />
      </div>
      <slot name="options" />
    </div>
  </div>
  <hr v-show="devider" class="mt-2 border-divider">
</template>

<script setup lang="ts">
interface Props {
  type?: "edit" | "new" | "none"
  title: string
  showSeparator?: boolean
  showAdditiolOptions?: boolean
  hideSaveButton?: boolean
  saving?: boolean
  devider?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  type: "edit",
  title: " ",
  showSeparator: true,
  showAdditiolOptions: true,
  hideSaveButton: false,
  saving: false,
  devider: true,
})
const emit = defineEmits(["exit", "back", "options", "save"])
provide<boolean>("editing", true)
const mobile = inject("isPhone") as Ref<boolean>
</script>

<style lang="scss" scoped></style>
