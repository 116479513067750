import type { IProject } from "@/types/DateTypes"
import type { QuoteStatuses } from "@/types"
import { useDataStore } from "@/stores/dataStore"
import { defineStore } from "pinia"
import { computed, ref } from "vue"

export const useProjectModel = defineStore("projectModel", () => {
  const dataStore = useDataStore()

  // State
  const activeProjectId = ref<string | null>(null)
  const activeProject = computed(() => {
    if (!activeProjectId.value) return null
    return dataStore.projectsById[activeProjectId.value]
  })
  const searchTerm = ref("")
  const filterStatus = ref<QuoteStatuses[]>([])

  // Computed Properties
  const filteredProjects = computed(() => { 
    let filtered = dataStore.projects

    if (filterStatus.value.length) {
      filtered = filtered.filter(p => filterStatus.value.includes(p.quote_status))
    }

    if (searchTerm.value) { 
      const query = searchTerm.value.toLowerCase().trim()
      filtered = filtered.filter(
        p =>
          p.name?.toLowerCase().includes(query)
          || (p.$customer)?.name?.toLowerCase().includes(query)
          || (p.$customer)?.city?.toLowerCase().includes(query),
      )
    }

    return filtered
  })

 

  function setSearchTerm(term: string) {
    searchTerm.value = term
  }

  return {
    // State
    activeProject,
    searchTerm,
    filterStatus,
    activeProjectId,

    // Computed
    filteredProjects,
    
    // Actions
    setSearchTerm,
  }
})
