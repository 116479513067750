import type { BrregCompany } from "@/types"
import type { ICustomer } from "@/types/DateTypes"
import api from "@/api"
import initials from "@/types/initialValues"
import type { DirectusContext } from "@/types"
import { defineStore } from "pinia"
import { useDataStore } from "@/stores/dataStore"
import { useGlobalStore } from "@/stores/globalStore"
import { computed, ref, watchEffect, type Ref } from "vue"
import { useRoute } from "vue-router"

export const useCustomerStore = defineStore("customer", () => {
  // State
  const filtered = ref<ICustomer[]>([])
  const customer = ref<ICustomer | null>(null)
  const companies = ref<BrregCompany[]>([])
  const loading = ref(false)
  const query = ref("")
  const brregCompanySelected = ref(false)
  const status = ref<"new" | "edit">("new")
  const globalStore = useGlobalStore()
  const dataStore = useDataStore()
  const route = useRoute()

  // Computed
  const customers = computed(() => {
    return Object.values(dataStore.customersById)
  })

  // Setup customer loading based on route
  watchEffect(async () => {
    
    const customerId = route.params.id as string
    if (customerId) {
      const foundCustomer = dataStore.customersById[customerId]
      if (foundCustomer) {
        customer.value = foundCustomer
        status.value = "edit"
      }
    } else {
      status.value = "new"
      customer.value = structuredClone(initials.emptyCustomerValue)
    }
  })

  // Getters
  const showBrregSearchBox = computed(() => 
    brregCompanySelected.value === false &&
    customer.value?.type === "company" &&
    status.value === "new" &&
    globalStore.tenant?.currency === "NOK"
  )

  // Helper Functions
  function capitalizeFirstLetter(text: string): string {
    if (text === "") return text
    return text[0].toUpperCase() + text.substring(1).toLowerCase()
  }

  function convertCompanyNameFormat(name: string): string {
    const nameFragments = name.split(" ").map(capitalizeFirstLetter)
    const lastIndex = nameFragments.length - 1
    if (["Asa", "As"].includes(nameFragments[lastIndex])) {
      nameFragments[lastIndex] = nameFragments[lastIndex].toUpperCase()
    }
    return nameFragments.join(" ")
  }

  function convertCityNameFormat(name: string): string {
    const nameFragments = name.split(" ").map((word: string, index: number) => {
      return word.length > 1 || index === 0
        ? capitalizeFirstLetter(word)
        : word.toLowerCase()
    })
    return nameFragments.join(" ").trim()
  }

  // API URLs
  function searchApiUrl(name: string) {
    return `https://data.brreg.no/enhetsregisteret/api/enheter?navn=${name}&konkurs=false&size=10`
  }

  function getApiUrl(orgNumber: string) {
    return `https://data.brreg.no/enhetsregisteret/api/enheter?organisasjonsnummer=${orgNumber}`
  }

  // Actions
  function search(searchQuery: string) {
    if (!customers.value.length) return

    const q = searchQuery.trim().toLocaleLowerCase()
    filtered.value = customers.value.filter((customer) => {
      return (
        customer.name.toLowerCase().includes(q) ||
        customer.city?.toLowerCase().includes(q)
      )
    })
  }

  async function searchBrreg(searchQuery: string, isPending: Ref<boolean>) {
    if (searchQuery.length >= 3) {
      isPending.value = true
      const result: BrregCompany[] = 
        (await (await fetch(searchApiUrl(searchQuery))).json())?._embedded?.enheter ?? []
      companies.value = result.map(item => ({
        ...item,
        navn: convertCompanyNameFormat(item.navn),
      }))
      isPending.value = false
    }
  }

  function handleSelect(companyInfo: BrregCompany) {
    const newCustomer: ICustomer = {
      id: "",
      status: "draft",
      name: convertCompanyNameFormat(companyInfo.navn),
      company_registration_number: `${companyInfo.organisasjonsnummer}`,
      email: "",
      phone_number: "",
      street_address: companyInfo.forretningsadresse.adresse[0],
      zip_code: companyInfo.forretningsadresse.postnummer,
      city: convertCityNameFormat(companyInfo.forretningsadresse.poststed),
      municipality: convertCityNameFormat(companyInfo.forretningsadresse.kommune),
      country_code: companyInfo.forretningsadresse.land,
      province: companyInfo.forretningsadresse.kommune,
      billing_street_address: companyInfo.forretningsadresse.adresse[0],
      billing_country_code: companyInfo.forretningsadresse.land,
      billing_zip_code: companyInfo.forretningsadresse.postnummer,
      billing_municipality: convertCityNameFormat(companyInfo.forretningsadresse.kommune),
      billing_city: convertCityNameFormat(companyInfo.forretningsadresse.poststed),
      billing_province: companyInfo.forretningsadresse.kommune,
      type: "company",
      currency: globalStore.tenant?.currency || "NOK",
      tenant: globalStore.tenant?.id || "",
      user_created: globalStore.user?.id || "",
      user_updated: globalStore.user?.id || "",
      date_created: new Date(),
      date_updated: new Date()
    }

    customer.value = newCustomer
    brregCompanySelected.value = true
  }

  async function create(): Promise<ICustomer> {
    try {
      if(!customer.value) {
        throw new Error("Customer is not defined")
      }
      const createdCustomer = await api.customers.createCustomer(customer.value) 
      return createdCustomer
    } catch (error) {
      console.error("🚀 - file: Offers.vue:74 - error:", error)
      throw error
    }
  }

  const directusContext = computed((): DirectusContext<"customers"> | undefined => {
    if (status.value === "edit" && customer.value?.id) {
      return {
        collection: "customers",
        id: customer.value.id,
      }
    }
    return undefined
  })

  

  return {
    // State
    customers,
    filtered,
    customer,
    companies,
    loading,
    query,
    brregCompanySelected,
    status,
    
    // Getters
    showBrregSearchBox,
    directusContext,
    
    // Actions
    search,
    searchBrreg,
    handleSelect,
    create,
    
    // Helper Functions
    searchApiUrl,
    getApiUrl,
    convertCompanyNameFormat,
    convertCityNameFormat,
    capitalizeFirstLetter,
  }
})
