<template>
  <div>
    <IconOptions class="w-10 h-10 p-2 mr-3" @click="() => (showOptions = true)" />
    <BottomDrawerSlot :show-options="showOptions" @close="() => (showOptions = false)">
      <Button
        type="secondary"
        size="large"
        class="w-full mb-10"
        @click="attemptDeleteCustomer(id)"
      >
        <div class="text-color-error">
          {{ t("shared.actions.delete") }}
        </div>
      </Button>
      <MobileDialog
        :sub-title="t('customers.cannot_delete_customer')"
        type="info"
        :show="showCannotDeleteInfo"
        @close="showCannotDeleteInfo = false"
      >
        <hr class="my-3">
        <div v-for="p of customerProjects" :key="p.name">
          <RouterLink 
            :to="{ name: 'quote', params: { quoteId: p.id }}" 
            class="text-label-2 text-color-link-static hover:text-color-link-hover"
          >
            {{ p.name }}
          </RouterLink>
        </div>
        <hr class="my-3">
      </MobileDialog>
    </BottomDrawerSlot>
  </div>
</template>

<script setup lang="ts">
import type { ICustomer } from "@/types/DateTypes"

import { t } from "@/localization/i18n"
import { useDeleteStore } from "@/stores/useDeleteStore"
import { useDataStore } from "@/stores/dataStore"
import { useRouter } from 'vue-router'

interface Props {
  id: string
}

const props = defineProps<Props>()
const showCannotDeleteInfo = ref(false)
const router = useRouter()
const showOptions = ref(false)

const customer = ref<ICustomer | undefined>(undefined)
const deleteStore = useDeleteStore()

const customerProjects = computed(() => customer.value && toValue(customer.value.$projects) || [])

async function attemptDeleteCustomer(id: string) {
  const dataStore = useDataStore()
  customer.value = dataStore.customersById[id]
  if (customerProjects.value.length) {
    showCannotDeleteInfo.value = true
  }
  else {
    deleteStore.setItemToDelete(id, "customers")
  }

    watch(
    () => deleteStore.itemDeleted,
    async (newValue) => {
      if (newValue && deleteStore.itemToDelete?.id === id) {
        await router.push({ name: "customers" })
        deleteStore.itemDeleted = false
      }
    },
  )

}


</script>
