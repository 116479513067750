import type { PDFEmailData } from "@/pdf-generator/types"
import type { User } from "@/types"
import type { ITenant, IProject } from "@/types/DateTypes"
import api from "@/api"
import { t } from "@/localization/i18n"
import { useProjectStore } from "@/stores/projectStore"
import { defineStore } from "pinia"

interface EmailState {
  emailData: PDFEmailData
  sending: boolean
  projectId: string
  projectStore: ReturnType<typeof useProjectStore>
}

export const useQuoteEmailStore = defineStore("email", {
  state: (): EmailState => ({
    emailData: {
      email: [],
      cc: [],
      subject: "",
      contact_person: "",
      tenant_name: "",
      creator_email: "",
      body: {
        p_1: "",
        p_2: "",
      },
    },
    sending: false,
    projectId: "",
    projectStore: useProjectStore(),
  }),
  actions: {
    async sendEmail(tenant: ITenant, accessToken: string) {
      this.sending = true
      await api.quotes.sendQuoteEmail(
        this.projectId,
        tenant,
        this.projectStore.project?.customer?.name ?? "",
        this.emailData,
        accessToken,
      )
      this.sending = false
    },
    initState(tenant: ITenant, project: IProject) {
      let creator_email = ""
      if (typeof project.user_created === "object" && project.user_created !== null) {
        creator_email = project.user_created.email ?? ""
      }

      this.projectId = project.id
      this.emailData = {
        email: [project.customer?.email ?? ""],
        cc: [],
        subject: project.name ?? "",
        contact_person: project.contact_person ?? "",
        tenant_name: tenant.name ?? "",
        creator_email,
        body: {
          p_1: `${tenant?.name} ${t("offer_p_1")}`,
          p_2: t("offer_p_2", { creator_email }),
        },
      }
    },
  },
})
